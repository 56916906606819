import '../css/hrl-mapping.css';
import {
  LazyLoadImage,
  LazyLoadComponent,
} from 'react-lazy-load-image-component';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

function HrlMapping() {
  const Dashboard = require('../img/mapping/dashboard.png');
  const Dashboard_LazyLoad = require('../img/mapping/dashboard-lazyload.jpg');

  const HRLDashboardImg = require('../img/mapping/HRLDashboard.png');
  const HRLDashboardImg_LazyLoad = require('../img/mapping/HRLDashboard-lazyload.jpg');

  const WorldMap = require('../img/mapping/worldmap.mov');
  const WorldMap_LazyLoadImg = require('../img/mapping/worldmap-lazyload.png');

  const totalStrikes = require('../img/mapping/Total strikes.png');
  const hrlStrikes = require('../img/mapping/hrl strikes.png');
  const ArrowRight = require('../img/mapping/arrowRight.png');

  //CWPP
  const CWPPHeader = require('../img/mapping/cwpp.png');
  const Line = require('../img/mapping/greyline.png');
  const FireFighter = require('../img/mapping/firefighter.png');
  const Image44 = require('../img/mapping/slideshow/image 44.png');
  const Image45 = require('../img/mapping/slideshow/image 45.png');
  const Image46 = require('../img/mapping/slideshow/image 46.png');
  const Image47 = require('../img/mapping/slideshow/image 47.png');
  const Image48 = require('../img/mapping/slideshow/image 48.png');
  const Image49 = require('../img/mapping/slideshow/image 49.png');
  const Image50 = require('../img/mapping/slideshow/image 50.png');
  const Image51 = require('../img/mapping/slideshow/image 51.png');

  function IntroVideo() {
    return (
      <div className="relative bg-black pb-32">
        <div className="absolute inset-0">
          {/* World Spinning Video */}
          <LazyLoadComponent
            placeholder={<img src={WorldMap_LazyLoadImg} alt="" />}
          >
            {/* Forest Video */}
            <video
              className="h-full w-full object-cover"
              src={WorldMap}
              autoPlay
              loop
              muted
            />
          </LazyLoadComponent>
          <div
            className="absolute inset-0 bg-gray-500 mix-blend-multiply"
            aria-hidden="true"
          />
        </div>
        {/* Page Title */}
        <div className="relative mx-auto max-w-7xl pt-24 px-4 sm:py-32 sm:pb-16 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold tracking-tight text-white md:text-5xl lg:text-6xl">
            High-Risk-Lightning™
          </h1>
          <p className="text-6xl font-bold tracking-tight text-white md:text-7xl lg:text-8xl mapping">
            Mapping
          </p>
        </div>
      </div>
    );
  }

  function HRLMappingServices() {
    return (
      <>
        {/* HRL™ Mapping Services Section */}
        <div className="grid-base margin-large">
          <h2 className="subheader text-white">HRL™ Mapping Services</h2>
          <div className="grid-base column2">
            <p className="text-lg text-gray-200">
              The final output of the HRL™ system is the mapping services
              integrated into our partner’s fiResponse platform. fiResponse is
              the leading wildfire behavior modeling software used by CalFire,
              FFS, and many other state agencies responsible for wildfire
              monitoring. An image of the FNN™ HRL™ dashboard for 2022 is shown
              below, with the HRL™ strike points and their associated fires next
              to them. This dashboard is where the live Firebird UAV footage is
              fed into for instant access by fire managers about potential
              ignition points.
            </p>

            <LazyLoadImage
              src={HRLDashboardImg}
              placeholderSrc={HRLDashboardImg_LazyLoad}
              alt={'HRL™ Detector Dashboard'}
            />
          </div>
          <div className="dashed-line"></div>
          <h3 className="slogan">
            Experience unparalleled convenience and efficiency with the Fire
            Neural Network™ GIS integrated dashboards.
          </h3>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0"></div>
          </div>
        </div>
        <div className="grid-base grid-gap-large margin-large column2 grid-auto-rows padding">
          <p className="block text-orange">
            Keep a comprehensive and real-time view of affected areas at your
            fingertips with our advanced alert system.
          </p>
          <p className="block text-orange">
            Easily interpret and analyze historical data to track trends over
            time and make informed decisions.
          </p>
        </div>
      </>
    );
  }

  function HRLDashboard() {
    return (
      <>
        {/* HRL™ Dashboard Section */}
        <div className="grid-base grid-gap-small margin-large">
          <h2 className="subheader text-white hrlDashboard">HRL™ Dashboard</h2>
          <h3 className="slogan text-orange justify-left text-uppercase ">
            Below:
          </h3>
          <h3 className="slogan justify-left">
            This is an example of the HRL™ dashboard, powered by ESRI ArcGIS,
            which is used to search through lightining strikes and resulting
            fires
          </h3>
        </div>
        {/* HRL™ Dashboard Image */}
        <div className="HRL InterDashboard">
          <LazyLoadImage
            src={Dashboard}
            placeholderSrc={Dashboard_LazyLoad}
            alt={'HRL™ and Fire Dashboard'}
          />
        </div>
      </>
    );
  }

  function HRLStrikes() {
    return (
      <>
        {/* Strikes Section */}
        <div className="grid-base column3 margin-small">
          <p className="block text-white style">
            In a given day, thousands of lightning strikes can occur. It is
            crucial to differentiate those that are capable of starting a fire.
          </p>
          <img
            className="arrowMap"
            src={ArrowRight}
            alt="Arrow pointing right"
          />
          <p className="block text-white">
            High-Risk-Lightning™ (HRL™) strikes are the strikes responsible for
            igniting fires. Recognizing these HRL™ strikes allows firefighting
            services to focus on affected areas in need.
          </p>
        </div>
        <div className="grid-base column3">
          <div className="MapImage-overlay">
            <img
              className=""
              src={totalStrikes}
              alt="Total lightning strikes"
            />
            <div className="MapImage-text">TOTAL STRIKES</div>
          </div>
          <img
            className="arrowMap"
            src={ArrowRight}
            alt="Arrow pointing right"
          />
          <div className="MapImage-overlay">
            <img className="" src={hrlStrikes} alt="HRL™ lightning strikes" />
            <div className="MapImage-text">HRL™ STRIKES</div>
          </div>
        </div>

        <div className="location">*Lake Berryessa, CA 2020</div>
      </>
    );
  }

  function CWPP() {
    const sliderSettings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
    };
    return (
      <>
        <div className="p-12"></div>
        <div className="position-relative" style={{ width: '100vw' }}>
          <img src={CWPPHeader} />

          <div
            className="position-absolute"
            style={{ transform: 'translate(2%, -120%)' }}
          >
            <h1 className="cwppTitle">Community Wildfire</h1>
            <h1 className="cwppTitle">Protection Plan</h1>
          </div>
        </div>
        <div className="gridContainer_1">
          <p className="text-white margin-large noMarginTopBottom">
            The Community Wildfire Protection Plan
            <span className="text-orange"> (CWPP) </span>
            is a unique opportunity to address the challenges of fire protection
            in the wildland/urban interface through locally-supported solutions.
            CWPPs are authorized by the Healthy Forests Restoration Act of 2003
            and provide communities with a tremendous opportunity to influence:
          </p>

          <div
            className="bulletPointsGridContainer
          "
          >
            <img src={Line} />
            <p className="circle px-2">1</p>
            <p className="text-orange px-2">
              where and how wildland fuel management projects are implemented on
              federally managed lands
            </p>
            <img src={Line} />
          </div>
          <div
            className="bulletPointsGridContainer
          "
          >
            <img src={Line} />
            <p className="circle px-2">2</p>
            <p className="text-orange px-2">
              how federal funds are distributed for fuel reduction projects on
              non-federal lands
            </p>
            <img src={Line} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={FireFighter} className="firefighterimg" />
          </div>
          <p className="text-white pt-8 margin-large noMarginTopBottom">
            The CWPP includes an assessment of the community’s wildfire
            vulnerability, local organizations and resources available to assist
            with wildfire mitigation and response, and an action plan for
            reducing wildfire vulnerability in the county. Recommended actions
            to reduce wildfire vulnerability have been collaboratively developed
            for the following mitigation categories:
          </p>

          <div
            className="bulletPointsGridContainer
          "
          >
            <img src={Line} />
            <p className="circle">1</p>
            <p className="text-orange px-2">Wildland fuel management</p>
            <img src={Line} />
          </div>
          <div
            className="bulletPointsGridContainer
          "
          >
            <img src={Line} />
            <p className="circle">2</p>
            <p className="text-orange px-2">Community outreach and education</p>
            <img src={Line} />
          </div>
          <div
            className="bulletPointsGridContainer
          "
          >
            <img src={Line} />
            <p className="circle">3</p>
            <p className="text-orange px-2">
              Firewise building retrofit and landscaping
            </p>
            <img src={Line} />
          </div>
          <div
            className="bulletPointsGridContainer
          "
          >
            <img src={Line} />
            <p className="circle">4</p>
            <p className="text-orange px-2">
              Policy and regulation recommendations
            </p>
            <img src={Line} />
          </div>
          <div
            className="bulletPointsGridContainer
          "
          >
            <img src={Line} />
            <p className="circle">5</p>
            <p className="text-orange px-2">
              Wildland fire response improvements
            </p>
            <img src={Line} />
          </div>
          <Slider {...sliderSettings}>
            <img src={Image44} />
            <img src={Image45} />
            <img src={Image46} />
            <img src={Image47} />
            <img src={Image48} />
            <img src={Image49} />
            <img src={Image50} />
            <img src={Image51} />
          </Slider>
        </div>
      </>
    );
  }

  return (
    <div>
      <IntroVideo />
      <HRLMappingServices />
      <HRLDashboard />
      <HRLStrikes />
      <CWPP />
    </div>
  );
}

export default HrlMapping;
