import '../css/hrl-firebird.css';
import Gallery from '../components/gallery';
import React from 'react';
import {
  LazyLoadComponent,
} from 'react-lazy-load-image-component';

function HrlFirebird() {
  const DroneTrees = require('../img/firebird/DRONETREES-ll.jpg');

  const SmokeDrone = require('../img/firebird/SmokeDRONE-ll.jpg');

  const MultiDrone = require('../img/firebird/MULTIDRONE-ll.jpg');

  const DroneRoadShot = require('../img/firebird/DRONEROADSHOT-ll.jpg');

  const Arrow = require('../img/firebird/arrow.png');
  const firebirdTop = require('../img/firebird/FireBirdTrailer.mov');
  const firebirdTop_LazyLoad = require('../img/firebird/FireBirdTrailer.jpg');

  const galleryData = [
    {
      img: require('../img/firebird/Carrossel1.jpg'),
      imgAlt: 'Firebird™ on table',
    },
    {
      img: require('../img/firebird/Carrossel2.jpg'),
      imgAlt: 'Close up of Firebird™',
    },
    {
      img: require('../img/firebird/Carrossel3.jpg'),
      imgAlt: 'Firebird™',
    },
    {
      img: require('../img/firebird/Carrossel4.jpg'),
      imgAlt: 'Firebird™ on lab',
    },
    {
      img: require('../img/firebird/Carrossel5.jpg'),
      imgAlt: 'Firebird™ production',
    },
    {
      img: require('../img/firebird/Carrossel6.jpg'),
      imgAlt: 'Firebird™ production close up',
    },
  
    {
      img: require('../img/firebird/Carrossel7.jpg'),
      imgAlt: 'Firebird™ close up',
    },
  
    {
      img: require('../img/firebird/Carrossel8.jpg'),
      imgAlt: 'Firebird™ close up',
    },
    {
      img: require('../img/firebird/Carrossel9.jpg'),
      imgAlt: 'Firebird™ close up',
    },
    {
      img: require('../img/firebird/Carrossel10.jpg'),
      imgAlt: 'Firebird™ close up',
    },
    {
      img: require('../img/firebird/Carrossel11.jpg'),
      imgAlt: 'Firebird™ close up',
    },
    {
      img: require('../img/firebird/Carrossel12.jpg'),
      imgAlt: 'Firebird™ close up',
    },
    {
      img: require('../img/firebird/Carrossel13.jpg'),
      imgAlt: 'Firebird™ close up',
    },
    {
      img: require('../img/firebird/Carrossel14.jpg'),
      imgAlt: 'Firebird™ close up',
    },
  
  ];

  // Firebird Intro Image HTML elements
  function IntroImage() {
    return (
      <>
        <div className="relative bg-black firebirdbanner">
          {/* Firebird Image */}
          <div className="absolute inset-0">
          <span className='overlay'></span>
          <LazyLoadComponent
              placeholder={<img src={firebirdTop_LazyLoad} alt="Shadow of Firebird™ in the air" />}
            >{/* Forest Video */}
            <video
              className="h-full w-full object-cover"
              src={firebirdTop}
              autoPlay
              loop
              muted
            />
          </LazyLoadComponent>

          </div>
          <div className="relative mx-auto max-w-7xl py-24 px-4 sm:py-32 sm:pb-16 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-bold tracking-tight text-white md:text-5xl lg:text-6xl">
              &nbsp;
            </h1>
            {/* Page Title */}
            <h1 className="text-6xl font-bold tracking-tight text-white md:text-7xl lg:text-8xl">
              Firebird™
            </h1>
          </div>
        </div>
        {/* Slogan */}
        <div className="ver-text">
          Verification is as important as detection
        </div>
      </>
    );
  }

  // Firebird Lines Section Flow Text static "database"
  const MappingFlowChartTextData = [
    {
      text: 'After the HRL™ strike has been detected, the next step is to verify whether an ignition has occurred at that point.',
    },
    {
      text: 'Customers can access real-time ignition footage via the proprietary FNN™ UAV, called Firebird™. This helps allocate the right amount of resources where they are needed the most.',
    },
    {
      text: 'Firebird™ has been developed specifically for the speed, range, and live communication abilities required for rapid ignition verification.',
    },
    {
      text: 'Other verification techniques include using proprietary FNN™ satellite hotspot verification algorithms to “see” if a fire is present at the HRL™ location.',
    },
  ];

  // Firebird Lines Section Flow HTML elements
  function MapFlowChart(props: any) {
    const textData = props.data;
    function LeftLines() {
      return (
        <>
          <div className="leftLines">
            <div className="line-1"></div>
            <div className="line-2"></div>
            <div className="line-3"></div>
          </div>
        </>
      );
    }
    function RightLines() {
      return (
        <>
          <div className="rightLines">
            <div className="line-1"></div>
            <div className="line-2"></div>
            <div className="line-3"></div>
          </div>
        </>
      );
    }

    function ArrowImage() {
      return <img className="arrowfb" src={Arrow} alt="Arrow pointing down" />;
    }

    return (
      <>
        {/* Firebird Lines Section */}
        <div className="relative">
          <div className="grid-firebird text-white">
            {textData.map((item: { text: string }, index: number) => {
              const isLastItem = index === textData.length - 1;
              return (
                <React.Fragment key={index}>
                  <div className={index % 2 ? 'right' : 'left'}>
                    {index % 2 ? null : <LeftLines />}
                    <h5 className={index % 2 ? 'right-text' : 'left-text'}>
                      {item.text}
                    </h5>
                    {index % 2 ? <RightLines /> : null}
                  </div>
                  {!isLastItem && <ArrowImage />}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </>
    );
  }

  // Youtube Video HTML element
  function YoutubeEmbededVideo() {
    return (
      <>
        {/* YouTube Embed Video */}
        <div className="youtube-container">
          <iframe
            width="150vh"
            height="315"
            src="https://www.youtube.com/embed/kZ9N6HeXAok?playlist=kZ9N6HeXAok&controls=0&autoplay=1&rel=0&showinfo=0&loop=1&mute=1&end=29.5​"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </>
    );
  }

  // Images or youtube video with Text Section static "database"
  const imgOverlayTextData = [
    {
      imgAlt: 'youtubeVideo',
      text: 'With Firebird™, we provide live footage of areas affected by High-Risk-Lightning™ through automated drones.',
    },
    {
      img: DroneTrees,
      imgAlt: 'Trees pictured from a drone',
      text: 'Even if firefighting services are on site immediately, it is often difficult to determine the area needed to be contained given tree coverage and smoke.',
    },
    {
      img: SmokeDrone,
      imgAlt: 'Multishot pictured from a drone',
      text: 'On a large scale, smoke coverage viewed from above can result in sending resources miles away from the actual source. With Firebird™, firefighting services can ensure they are focusing their efforts to the proper location.',
    },
    {
      img: MultiDrone,
      imgAlt: 'Smoke pictured from a drone',
      text: 'Spotting these burn marks can be difficult, an aerial view often does not always provide a clear image. The Firebird™ thermal filtering can help pinpoint exactly where the burn is occuring.',
    },
    {
      img: DroneRoadShot,
      imgAlt: 'Road pictured from a drone',
      text: 'An uncontrolled wildfire doubles in size every 3-5 minutes at inception. Firebird™ provides an immediate and ongoing visual of this expansion.',
    },
  ];

  // Images or youtube video with Text Section mapping database elements
  const imgOverlayTextDataElements = imgOverlayTextData.map((item) => {
    return (
      <ImgyTextSection
        key={item.imgAlt}
        img={item.img}
        imgAlt={item.imgAlt}
        text={item.text}
      />
    );
  });

  // Images or youtube video with Text Section HTML elements
  function ImgyTextSection(props: { img: any; imgAlt: string; text: string }) {
    const { img, imgAlt, text } = props;
    if (imgAlt === 'youtubeVideo') {
      return (
        <>
          {/* Youtube Video with Text Section */}
          <div className="fbird-image-overlay">
            <YoutubeEmbededVideo />
            <div className="fbird-overlay">
              <span className="separator"></span>
              <p className="fbird-text">{text}</p>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          {/* Images with Text Section */}
          <div className="fbird-image-overlay">
            <img src={img} alt={imgAlt} />
            <div className="fbird-overlay">
              <span className="separator"></span>
              <p className="fbird-text">{text}</p>
            </div>
          </div>
        </>
      );
    }
  }

  // Complete Page Render
  return (
    <div>
      <IntroImage />
      <MapFlowChart data={MappingFlowChartTextData} />
      <div className="grid-base">{imgOverlayTextDataElements}</div>
      <Gallery data={galleryData} />
    </div>
  );
}

export default HrlFirebird;
