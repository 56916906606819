import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

export default function Gallery(props: any) {
  const galleryData = props.data;
  const slidesElements = galleryData.map((slide: any) => (
    <SwiperSlide key={slide.imgAlt}>
      <img
        src={slide.img}
        alt={slide.imgAlt}
        className="Carouselpics"
        style={{ maxHeight: '80vh' }}
      />
    </SwiperSlide>
  ));
  return (
    <>
      {/* Gallery Section */}
      <h1 className="overview">Gallery</h1>
      <div className="">
        <Swiper
          navigation={true}
          modules={[Navigation]}
          className="mx-auto"
          loop={true}
          height={600}
          autoHeight={true}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
        >
          {slidesElements}
        </Swiper>
      </div>
    </>
  );
}
