import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Skeleton } from 'react-skeleton-generator';

import { getFiresList, FireList } from '../api/fires';
const Hotspot_header = require('../img/HotspotsHeader.png');

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

function Hotspot() {
  const [isLoading, setIsLoading] = useState(true);
  const [firesList, setFiresList] = useState<FireList>();
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedArea, setSelectedArea] = useState('California');

  const loadFires = () => {
    setIsLoading(true);

    getFiresList(selectedArea, selectedPage).then((res) => {
      setFiresList(res);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    loadFires();
  }, [selectedArea, selectedPage]);

  const handleClickPrevious = () => {
    if (firesList?.previous !== null) {
      setSelectedPage(selectedPage - 1);
      loadFires();
    }
  };

  const handleClickNext = () => {
    if (firesList?.next !== null) {
      setSelectedPage(selectedPage + 1);
      loadFires();
    }
  };

  return (
    <div>
      <div className="relative bg-black pb-32">
        {/* Background image and header text */}
        <div className="absolute inset-0">
          <img
            className="h-full w-full object-cover"
            src={Hotspot_header}
            alt="3D model of the Firebird with a mesh background"
          />
        </div>
        <div className="relative mx-auto max-w-7xl py-24 px-4 sm:py-32 sm:pb-16 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold tracking-tight text-white md:text-5xl lg:text-6xl">
            &nbsp;
          </h1>
          <h1 className="text-6xl font-bold tracking-tight text-white md:text-7xl lg:text-8xl">
            FIRE Hotspots
          </h1>
        </div>
      </div>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="xs:mx-2 sm:mx-6 width-90">
            <span className="isolate inline-flex rounded-md shadow-sm">
              <button
                type="button"
                className={classNames(
                  selectedArea === 'California'
                    ? 'z-10 border-emerald-500 outline-none ring-1 ring-emerald-800'
                    : 'border-gray-600',
                  'relative -ml-px inline-flex items-center rounded-l-md border-2 bg-gray-900 px-4 py-2 text-sm font-medium text-gray-300 hover:bg-gray-800 hover:text-gray-200'
                )}
                onClick={() => setSelectedArea('California')}
              >
                California
              </button>
              <button
                type="button"
                className={classNames(
                  selectedArea === 'Brazil'
                    ? 'z-10 border-emerald-500 outline-none ring-1 ring-emerald-800'
                    : 'border-gray-600',
                  'relative -ml-px inline-flex items-center rounded-r-md border-2 bg-gray-900 px-4 py-2 text-sm font-medium text-gray-300 hover:bg-gray-800 hover:text-gray-200'
                )}
                onClick={() => setSelectedArea('Brazil')}
              >
                Brazil
              </button>
            </span>

            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm ring-1 ring-black ring-opacity-5 rounded-lg">
                <table
                  className="bg-gray-900 min-w-full border-separate"
                  style={{ borderSpacing: 0 }}
                >
                  <thead className="bg-gray-900 text-gray-300">
                    <tr>
                      <th
                        scope="col"
                        className="sticky bg-gray-900 top-0 z-10 border-b border-gray-300 py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 lg:pl-8"
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        className="sticky bg-gray-900 top-0 z-10 hidden border-b border-gray-300 px-3 py-3.5 text-left text-sm font-semibold sm:table-cell"
                      >
                        Latitude
                      </th>
                      <th
                        scope="col"
                        className="sticky bg-gray-900 top-0 z-10 hidden border-b border-gray-300 px-3 py-3.5 text-left text-sm font-semibold lg:table-cell"
                      >
                        Longitude
                      </th>
                      <th
                        scope="col"
                        className="sticky bg-gray-900 top-0 z-10 border-b border-gray-300 px-3 py-3.5 text-left text-sm font-semibold"
                      >
                        Timestamp
                      </th>
                      <th
                        scope="col"
                        className="sticky bg-gray-900 top-0 z-10 border-b border-gray-300 py-3.5 pr-4 pl-sm:pr-6 lg:pr-8"
                      >
                        <span className="sr-only">View Details</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-gray-800 text-gray-300">
                    {isLoading && (
                      <tr>
                        <td colSpan={5}>
                          <Skeleton.SkeletonThemeProvider
                            color="rgb(31, 45, 55)"
                            animationDuration={3}
                          >
                            <Skeleton count={25} width="100%" />
                          </Skeleton.SkeletonThemeProvider>
                        </td>
                      </tr>
                    )}
                    {!isLoading &&
                      firesList?.results.map((fire, fireIdx) => (
                        <tr key={fire.id}>
                          <td
                            className={classNames(
                              fireIdx !== firesList.results.length - 1
                                ? 'border-b border-gray-200'
                                : '',
                              'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-mediumsm:pl-6 lg:pl-8'
                            )}
                          >
                            {fire.id}
                          </td>
                          <td
                            className={classNames(
                              fireIdx !== firesList.results.length - 1
                                ? 'border-b border-gray-200'
                                : '',
                              'whitespace-nowrap px-3 py-4 text-sm hidden sm:table-cell'
                            )}
                          >
                            {fire.latitude}
                          </td>
                          <td
                            className={classNames(
                              fireIdx !== firesList.results.length - 1
                                ? 'border-b border-gray-200'
                                : '',
                              'whitespace-nowrap px-3 py-4 text-sm hidden lg:table-cell'
                            )}
                          >
                            {fire.longitude}
                          </td>
                          <td
                            className={classNames(
                              fireIdx !== firesList.results.length - 1
                                ? 'border-b border-gray-200'
                                : '',
                              'whitespace-nowrap px-3 py-4 text-sm'
                            )}
                          >
                            {new Date(fire.timestamp).toDateString()}
                          </td>
                          <td
                            className={classNames(
                              fireIdx !== firesList.results.length - 1
                                ? 'border-b border-gray-200'
                                : ''
                            )}
                          >
                            <Link
                              to={'/hotspot/' + fire.id}
                              type="button"
                              className="float-right mr-4 my-2 rounded-md border border-transparent background-primary p-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:w-auto"
                            >
                              View Details
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {/* Footer nav for pagination */}
              <nav
                className="flex items-center justify-between border-t border-gray-200 bg-gray-900 px-4 py-3 sm:px-6"
                aria-label="Pagination"
              >
                <div className="hidden sm:block">
                  <p className="text-sm text-gray-200">
                    Showing{' '}
                    <span className="font-medium">
                      {selectedPage * 25 - 24}
                    </span>{' '}
                    to{' '}
                    <span className="font-medium">
                      {Math.min(selectedPage * 25, firesList?.count || 0)}
                    </span>{' '}
                    of <span className="font-medium">{firesList?.count}</span>{' '}
                    results
                  </p>
                </div>
                <div className="flex flex-1 justify-between sm:justify-end">
                  {firesList?.previous !== null && (
                    <button
                      className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                      onClick={handleClickPrevious}
                    >
                      Previous
                    </button>
                  )}
                  {firesList?.next !== null && (
                    <button
                      type="button"
                      className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                      onClick={handleClickNext}
                    >
                      Next
                    </button>
                  )}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hotspot;
