import React, { useState, useEffect, useRef } from 'react';
import '../css/apply-form.css';

function ApplyForm() {
  const [showSubmissionMessage, setShowSubmissionMessage] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  function submitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    fetch('http://localhost:8000/api/submit-application/', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setShowSubmissionMessage(true);
        setTimeout(() => {
          setFadeOut(true);
          setTimeout(() => setShowSubmissionMessage(false), 1000); // Duration of fade-out
        }, 9000); // Show message for 9 seconds then fade out
      })
      .catch((error) => console.error('Error:', error));
  }

  const handleFileButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (showSubmissionMessage) {
      setFadeOut(false);
      timer = setTimeout(() => {
        setFadeOut(true);
        setTimeout(() => setShowSubmissionMessage(false), 1000); // Duration of fade-out
      }, 4000); // Show message for 9 seconds then fade out
    }
    return () => clearTimeout(timer);
  }, [showSubmissionMessage]);

  return (
    <div className="flex-container-0">
      <h2 className="text-4xl font-regular text-orange sm:text-4xl">
        APPLICATION
      </h2>
      <form onSubmit={submitForm} encType="multipart/form-data">
        <div className="flex-container-1">
          <div className="name-row">
            <div>
              <p className="text-box-label">First Name: </p>
              <input
                type="text"
                name="first_name"
                required
                className="custom-input-names"
              />
            </div>

            <div>
              <p className="text-box-label">Last Name:</p>
              <input
                type="text"
                name="last_name"
                required
                className="custom-input-names"
              />
            </div>
          </div>
          <div>
            <p className="text-box-label">Email:</p>
            <input
              type="email"
              name="email"
              required
              className="custom-input"
            />
          </div>
          <div>
            <p className="text-box-label">Phone:</p>
            <input type="tel" name="phone" required className="custom-input" />
          </div>
          <div>
            <p className="text-box-label">Specialty: </p>
            <select name="specialty" className="custom-dropdown-menu" required>
              <option value=""></option>
              <option value="Computer Science">Computer Science</option>
              <option value="Electrical Engineering">
                Electrical Engineering
              </option>
              <option value="Geographic Information System">
                Geographic Information System
              </option>
              <option value="Marketing">Marketing</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="form-group">
            <p className="text-box-label">Resume: {fileName}</p>
            <input
              type="file"
              name="file"
              accept=".pdf, .doc, .docx"
              required
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={(e) => setFileName(e.target.files?.[0]?.name || "")}
            />
            <button
              type="button"
              className="btn-upload"
              onClick={handleFileButtonClick}
            >
              Choose File
            </button>
          </div>
          <div className="submit-btn-container">
            <button type="submit" className="submit-btn">
              Submit
            </button>
          </div>
        </div>
      </form>
      {showSubmissionMessage && (
        <div className={`notification ${fadeOut ? 'fadeOut' : 'fadeIn'}`}>
          <h2 className="text-4xl font-regular text-orange sm:text-4xl">
            SUCCESS!
          </h2>
          <h3 className="text-1xl font-regular text-white sm:text-1xl">
            THANK YOU FOR APPLYING TO FIRE NEURAL NETWORK! YOUR APPLICATION HAS
            BEEN RECEIVED
          </h3>
        </div>
      )}
    </div>
  );
}

export default ApplyForm;
