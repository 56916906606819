import CardGrid from '../components/card-grid';
import React, { useState } from 'react';
import '../css/news.css';

type Post = {
  title: string;
  href: string;
  date: string;
  datetime: string;
  imageUrl: any;
  newTab: boolean;
  isNews: boolean;
  isMultimedia: boolean;
};

const posts: Post[] = [
  {
    title: 'Finding Fires with Lightning Speed',
    href: 'https://news.ufl.edu/2023/04/fire-neural-network/#:~:text=FNN%20has%20created%20what%20could%20be%20called%20a,the%20forest%20floor%20that%20fuels%20the%20bad%20fires',
    date: 'April 14, 2023',
    datetime: '2023-04-14',
    imageUrl: require('../img/detector/CarouselDetector2-lazyload.jpg'),
    newTab: true,
    isNews: true,
    isMultimedia: false,
  },
  {
    title: 'Fire Neural Network wins UF AI Days Pitch Competition',
    href: 'https://news.warrington.ufl.edu/students/fire-neural-network-wins-uf-ai-days-pitch-competition/',
    date: 'Nov 7, 2022',
    datetime: '2022-11-07',
    imageUrl: require('../img/news/ai-pitch.jpg'),
    newTab: true,
    isNews: true,
    isMultimedia: false,
  },
  {
    title: 'Low-earth satellites with sensors, AI to tame bushfires',
    href: 'https://www.afr.com/politics/federal/low-earth-satellites-with-sensors-ai-to-tame-bushfires-20220916-p5bild',
    date: 'Sep 18, 2022',
    datetime: '2022-09-18',
    imageUrl: require('../img/news/bushfires.jpg'),
    newTab: true,
    isNews: true,
    isMultimedia: false,
  },
  {
    title: 'Fire Neural Network wins 2022 Big Idea Competition',
    href: 'https://news.warrington.ufl.edu/programs/fire-neural-network-wins-2022-big-idea-competition/',
    date: 'Apr 26, 2022',
    datetime: '2022-04-26',
    imageUrl: require('../img/news/big-idea.jpg'),
    newTab: true,
    isNews: true,
    isMultimedia: false,
  },
  {
    title: 'NSSN using FNN detectors',
    href: 'https://www.nssn.org.au/news/2023/10/11/promising-sensors-detect-high-risk-lightning-strikes-which-help-avoid-catastrophic-bushfires',
    date: 'Sep 09, 2023',
    datetime: '2023-09-09',
    imageUrl: require('../img/news/NSSNarticle-ll.jpg'),
    newTab: true,
    isNews: true,
    isMultimedia: false,
  },
  {
    title:
      'Wildfire Drones: Detect, Coordinate, Contain Fighting the Wildfire Upsurge',
    href: 'https://aerialfiremag.com/2023/11/06/wildfire-drones-detect-coordinate-contain-fighting-the-wildfire-upsurge/',
    date: 'Nov 06, 2023',
    datetime: '2023-11-06',
    imageUrl: require('../img/news/article_2023-11-06.jpg'),
    newTab: true,
    isNews: true,
    isMultimedia: false,
  },
  {
    title:
      'This device can detect the one lightning strike likely to cause a wildfire. Here’s how it works.',
    href: 'https://www.clickorlando.com/news/investigators/2023/12/12/this-device-can-detect-the-one-lightning-strike-likely-to-cause-a-wildfire-heres-how-it-works/',
    date: 'Dec 12, 2023',
    datetime: '2023-12-12',
    imageUrl: require('../img/news/article_2023-12-12.avif'),
    newTab: true,
    isNews: true,
    isMultimedia: false,
  },
  {
    title:
      'Meet the 7 new startups welcomed into the 3rd 35 mules cohort at FPL',
    href: 'https://techhubsouthflorida.org/meet-the-7-new-startups-welcomed-into-the-3rd-35-mules-cohort-at-fpl/?utm_campaign=The%20BRIEF%20newsletter%20%7C%20eMerge%20Americas%202023&utm_source=hs_email&utm_medium=email&utm_content=284202533&_hsenc=p2ANqtz--NLS7yEv91BoB5mGM55qsin9poe-P2zN9ENN7-Wh8Aug3FbBPNC8Xz2G9JxJYXcuu9nrfs5GmAaT66m_ajX11tljuBk8H6SNCsGlqL57W_3MsR5YI',
    date: 'Nov 19, 2023',
    datetime: '2023-11-19',
    imageUrl: require('../img/news/article_2023-11-19.png'),
    newTab: true,
    isNews: true,
    isMultimedia: false,
  },
  {
    title: 'High-Risk-Lightning Detection Can Prevent Disaster',
    href: 'https://storymaps.arcgis.com/stories/5437342dd9374aa3910504749265563f',
    date: 'Nov 22, 2022',
    datetime: '2022-11-22',
    imageUrl: require('../img/stories/bert-nass.jpg'),
    newTab: true,
    isNews: false,
    isMultimedia: false,
  },
  {
    title: 'Welcome Fire',
    href: '/welcomefire',
    date: 'Sep 09, 2023',
    datetime: '2023-09-09',
    imageUrl: require('../img/stories/welcome-fire-ll.jpg'),
    newTab: false,
    isNews: false,
    isMultimedia: false,
  },
  {
    title: '2023 Fires in Recap',
    href: 'https://storymaps.arcgis.com/stories/df47c927f1e642b8ae2bc4dcde8d8496',
    date: 'Dec 13, 2023',
    datetime: '2023-12-13',
    imageUrl: require('../img/stories/firesrecap.png'),
    newTab: false,
    isNews: false,
    isMultimedia: false,
  },
  {
    title: 'ECE RESEARCHERS POWER FIRE NEURAL NETWORK',
    href: 'https://news.ece.ufl.edu/2023/05/24/ece-researchers-power-fire-neural-network/',
    date: 'May 24, 2023',
    datetime: '2023-5-24',
    imageUrl: require('../img/news/ECE.png'),
    newTab: false,
    isNews: true,
    isMultimedia: false,
  },
  {
    title:
      'Which lightning strikes ignite bushfires? A review of Fire Neural Network (FNN) High-Risk-Lightning research in Florida and Brazil',
    href: 'https://fennerschool.anu.edu.au/news-events/events/which-lightning-strikes-ignite-bushfires-review-fire-neural-network-fnn-high-risk',
    date: 'Feb 9, 2023',
    datetime: '2023-2-9',
    imageUrl: require('../img/news/AustraliaBushfireHRL.png'),
    newTab: false,
    isNews: true,
    isMultimedia: false,
  },
  {
    title:
      'Tech Tuesday - Fire Neural Network: Helping detect wildfire severities with the help of AI',
    href: 'https://www.youtube.com/watch?v=lojPUc9_awI',
    date: 'June 14, 2023',
    datetime: '2023-6-14',
    imageUrl: require('../img/multimedia_thumbnails/techtuesday.png'),
    newTab: false,
    isNews: false,
    isMultimedia: true,
  },
  {
    title: 'How AI is Being Used to Prevent Wildfires',
    href: 'https://www.youtube.com/watch?v=Q7J_wXmWoaA',
    date: 'Feb 2, 2024',
    datetime: '2024-2-2',
    imageUrl: require('../img/multimedia_thumbnails/aiWildfires.png'),
    newTab: false,
    isNews: false,
    isMultimedia: true,
  },
  {
    title: 'Fire University - Finding fires lightning fast',
    href: 'https://naturalresourcesuniversity.libsyn.com/fire-university-finding-fires-lightning-fast-174',
    date: 'July 26, 2023',
    datetime: '2023-7-26',
    imageUrl: require('../img/multimedia_thumbnails/firepodcast.jpg'),
    newTab: false,
    isNews: false,
    isMultimedia: true,
  },
];

const News = () => {
  const [displayMode, setDisplayMode] = useState('all'); // Include 'videos' in the possible states

  const handleButtonClick = (mode: any) => {
    setDisplayMode(displayMode === mode ? 'all' : mode);
  };

  // Filter posts based on the display mode
  const filteredPosts = posts.filter((post) => {
    if (displayMode === 'all') return true;
    if (displayMode === 'news') return post.isNews;
    if (displayMode === 'stories') return !post.isNews && !post.isMultimedia;
    return post.isMultimedia; // New filter for video posts
  });

  // Sort posts
  const sortedPosts = filteredPosts.sort(
    (a, b) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime()
  );

  // Button styling
  const getButtonClass = (mode: any) => {
    return `btn btn-light custom-btn${
      displayMode === mode ? ' grey-button' : ''
    }`;
  };

  return (
    <div>
      <div className="text-center mt-40">
        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
          Latest News
        </h2>
        <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-500 sm:mt-4">
          Internal and External publications about Fire Neural Network
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
        }}
      >
        <button
          className={getButtonClass('news')}
          onClick={() => handleButtonClick('news')}
        >
          News
        </button>
        <button
          className={getButtonClass('stories')}
          onClick={() => handleButtonClick('stories')}
          style={{ marginLeft: '15px' }}
        >
          Stories
        </button>
        {/* New button for videos */}
        <button
          className={getButtonClass('multimedia')}
          onClick={() => handleButtonClick('multimedia')}
          style={{ marginLeft: '15px' }}
        >
          Multimedia
        </button>
      </div>
      <CardGrid cards={sortedPosts} />
    </div>
  );
};

export default News;
