import { Fragment, useState } from 'react';
import { Dialog, Popover, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link, NavLink } from 'react-router-dom';
import { mainRoutes, secondaryRoutes } from '../routes';

export default function Header() {
  const Logo = require('../img/FNN_LOGO_letters_Navbar.png');
  
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Popover className="header bg-opacity-40">
      <div className="flex items-center justify-between px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
        <div>
          <Link to="/" className="flex">
            <span className="sr-only">Fire Neural Network</span>
            <img className="h-8 w-auto sm:h-10" src={Logo} alt="" />
          </Link>
        </div>
        <div className="flex flex-1 items-center justify-end md:justify-between">
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            {mainRoutes.map((route) => {
              return (
                <NavLink
                  to={route.to}
                  key={route.to}
                  className={({ isActive }) =>
                    isActive
                      ? 'text-base font-medium text-gray-200 hover:text-white'
                      : 'text-base font-medium text-gray-400 hover:text-white'
                  }
                >
                  {route.name}
                </NavLink>
              );
            })}
          </Popover.Group>
          <div className="flex items-center md:ml-12">
            <div
              onClick={() => setMenuOpen(!menuOpen)}
              className="cursor-pointer ml-8 inline-flex items-center justify-center rounded-md border border-transparent background-primary px-4 py-2 text-base font-medium text-white shadow-sm"
            >
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>

      <Transition.Root show={menuOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setMenuOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen h-full max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-black bg-opacity-95 py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 cursor-pointer"
                              onClick={() => setMenuOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 w-full px-8 sm:px-6">
                        <ul className="divide-y divide-gray-600">
                          {/* Only show the main routes when we're on mobile; otherwise they're in the header */}
                          {mainRoutes.map((menuItem) => (
                            <li
                              key={menuItem.to}
                              className="flex py-4 w-full md:hidden"
                              onClick={() => setMenuOpen(false)}
                            >
                              <Link
                                to={menuItem.to}
                                className="text-base font-medium text-gray-400 hover:text-white w-full h-full"
                              >
                                {menuItem.name}
                              </Link>
                            </li>
                          ))}
                          {/* Always show the secondary routes */}
                          {secondaryRoutes.map((menuItem) => (
                            <li
                              key={menuItem.to}
                              className="flex py-4 w-full"
                              onClick={() => setMenuOpen(false)}
                            >
                              <Link
                                to={menuItem.to}
                                className="w-full h-full text-base font-medium text-gray-400 hover:text-white"
                              >
                                {menuItem.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Popover>
  );
}
